.hamburgerpg {
    position: absolute;
    width: 70%;

}

.firstcomponentinhomepage {
    background-image: url("https://betro.s3.eu-central-1.amazonaws.com/uzay.png");
    background-size: cover;
    background-position: center;
    color: white
}
.imgto{
    width: 100%;
    position: absolute;
    top:-130px;
}

.pagewriting {
    width: 80%;
    position: absolute;
    z-index: 0;
    top: 0px;
}

.startone {
    position: absolute;
    top: 50%;
    left: 15%;
    width: 25%;
    transform: rotate(180deg);
}

.startto {
    position: absolute;
    width: 25%;
    top: 20%;
    right: 10%;
    transform: rotate(60deg);
}

.containerone {
    background-image: url("https://betro.s3.eu-central-1.amazonaws.com/uzay.png");
    background-size: cover;
    background-position: center;
    z-index: -999;
    height: auto;
}

.purple {
    background-image: url("https://betro.s3.eu-central-1.amazonaws.com/Katman13.png");
    background-size: cover;
    background-position: center;
    z-index: -999;
    height: auto;
    color: white;
    padding-left: 5%;

}

.containerto {
    position: relative;
    width: 100%;
    height: 900px;
    display: flex;

    background-color: black;
}

.containertree {
    position: relative;
    z-index: 1117;
    width: 100%;
    height: 900px;
    background-image: url("https://betro.s3.eu-central-1.amazonaws.com/Katman13.png");
    background-size: cover;
    background-position: center;
}

.container-one-section-one {
    position: relative;
    height: 500px;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}

.container-one-section-to {
    display: flex;
    height: 650px;
}

.holdingburgerimg {
    position: absolute;
    right: 0;
    width: 80%;
    z-index: 10;
    top: 100px;
    transform: rotate(60deg);
}

.handimg {
    right: 0;
    position: absolute;
    width: 40%;
    padding-bottom: 150px;
}

.textside {
    margin-top: 200px;
    margin-left: 100px;
    color: white
}

.heykel {
    top: -200px;
    position: absolute;
    width: 55%;
    height: auto;
    z-index: 1001;
}

.yessembol {
    width: 30%;
    position: absolute;
    bottom: 0;
    left: 23%;
    z-index: 144111;
}

.ortam {
    overflow: hidden;
}

.ok {
    position: absolute;
    top: 20%;
    left: 30%;
    width: 30%;

}

.textsidecontainerto {
    width: 40%;
    padding-top: 20%;
    text-align: right;
    color: white;
    position: absolute;
    right: 5%;
}

.containertree-textside {
    color: white;

}

.containertree-imgside {}

.astronotpicto {
    position: absolute;
    bottom: 30%;
    right: 300px;
    height: auto;
    width: 200px;
    right: 50px;
    z-index: 3;
}

.hamburgerpicto {
    position: absolute;
    bottom: 0;
    right: 0;
    height: auto;
    width: 80%;
}

.serit {
    width: 100%;
    position: absolute;
    top: 0
}

.line {
    width: 100%;
    position: absolute;
    bottom: -10%;
    z-index: 900000;
}

.ortam .homepage-text-1 {
    font-family: 'cindie', sans-serif;
    font-size: 30px;
    margin: 0;
}

.ortam .homepage-text-2 {
    font-family: 'cindie', sans-serif;
    font-size: 80px;
    margin: 0;
}

.ortam .homepage-text-3 {
    font-size: 14px;
    font-weight: bold;
}

.ortam .homepage-text-4 {
    font-size: 50px;
    font-family: 'cindie', sans-serif;
    font-weight: bold;
    color: #48ac37;
}

.ortam .homepage-text-5 {
    font-size: 80px;
    font-family: 'cindie', sans-serif;
    font-weight: bold;
    color: white;
    margin: 0;
}

.home-btn-1 {
    padding: 3px;
    background-color: #47a935;
    border-radius: 50px;
    width: 250px;
    cursor: pointer;
}

.home-btn-2 {
    background-color: #47a935;
    border: 3px solid #111411;
    border-radius: 50px;
    font-family: 'cindie', sans-serif;
    padding: 10px 5px;
    text-align: center;
    font-size: 20px;
}

.welcome-text {
    width: 70%;
    animation: homepage-animation 4s step-end infinite;
}

.homepage-hamburger {
    width: 70%;
    margin-top: -300px;
    animation: homepage-animation 4s step-end infinite;
}
.start-one{
    width: 20%;
    position: absolute;
    top:16%;
    left:64%;
    animation: homepage-animation 4s step-end infinite;
    transform: rotate(45deg);
}
.start-ones{
    width: 20%;
    position: absolute;
    top:26%;
    left:20%;
    animation: homepage-animation 4s step-end infinite;
}
.start-to {
    width: 10%;
    position: absolute;
    animation: homepage-animation 4s step-end infinite;
}

@keyframes homepage-animation {
    0% {
        filter: none;
    }

    10% {
        filter: contrast(130%) drop-shadow(-3px 1px 5px #5cefb5) invert(10%);
    }
    
    30% {
        filter: none;
    }

    79% {
        filter: none;
    }

    80% {
        filter: contrast(130%) drop-shadow(-3px 1px 5px #5cefb5) invert(10%);
    }

    100% {
        filter: none;
    }
}

@media only screen and (max-width: 1200px) {
    .container-one-section-one {
        height: 500px;

    }

    .container-one-section-to {
        height: 500px;

    }

    .heykel {

        top: -70px;
    }

    .containerto {
        height: 800px;
    }
    .homepage-hamburger {
        margin-top: -200px;
    }
    .ortam .homepage-text-5 {
        font-size: 60px;
    }
}

@media only screen and (max-width: 950px) {
    .container-one-section-one {
        height: 400px;

    }

    .container-one-section-to {
        height: 400px;

    }

    .heykel {

        top: -70px;
    }

    .containerto {
        height: 700px;
    }
    .homepage-hamburger {
        margin-top: -150px;
    }

    .ortam .homepage-text-1 {
        font-size: 25px;
        margin: 0;
    }
    
    .ortam .homepage-text-2 {
        font-size: 70px;
        margin: 0;
    }
    .ortam .homepage-text-4 {
        font-size: 50px;
    }
    
    .ortam .homepage-text-6 {
        font-size: 14px;
    }
    .ortam .homepage-text-5 {
        font-size: 50px;
    }
}

@media only screen and (max-width: 870px) {
    .container-one-section-one {
        height: 350px;

    }

    .container-one-section-to {
        height: 350px;

    }

    .astronotpicto {
        width: 200px;
    }

    .containertree {
        height: 700px;
    }

    .heykel {

        top: -70px;
    }

    .containerto {
        height: 650px;
    }
    .homepage-hamburger {
        margin-top: -120px;
    }
    .ortam .homepage-text-1 {
        font-size: 20px;
        margin: 0;
    }
    
    .ortam .homepage-text-2 {
        font-size: 50px;
        margin: 0;
    }
    .ortam .homepage-text-4 {
        font-size: 40px;
    }
    
    .ortam .homepage-text-6 {
        font-size: 14px;
    }
    .textsidecontainerto {
        padding-top: 15%;
    }
    .ortam .homepage-text-5 {
        font-size: 40px;
    }
}

@media only screen and (max-width: 680px) {
    .container-one-section-one {
        height: 280px;

    }

    .container-one-section-to {
        height: 280px;

    }

    .astronotpicto {
        width: 150px;
    }

    .containertree {
        height: 500px;
    }

    .heykel {

        top: -70px;
    }

    .containerto {
        height: 550px;
    }
    .homepage-hamburger {
        margin-top: -120px;
    }
    .ortam .homepage-text-1 {
        font-size: 18px;
        margin: 0;
    }
    
    .ortam .homepage-text-2 {
        font-size: 30px;
        margin: 0;
    }
    .ortam .homepage-text-4 {
        font-size: 30px;
    }
    
    .ortam .homepage-text-6 {
        font-size: 12px;
    }
    .ortam .homepage-text-5 {
        font-size: 30px;
    }
}

@media only screen and (max-width: 600px) {
    .container-one-section-one {
        height: 250px;

    }

    .containertree {
        height: 400px;
    }

    .heykel {
        width: 63%;
        top: -50px;
    }

    .containerto {
        height: 370px;
    }

    .containertree {
        height: 400px;
    }

    .astronotpicto {
        width: 100px;
        right: 50px;
        bottom: 150px;
    }

    .container-one-section-to {
        height: 250px;

    }
    .homepage-hamburger {
        margin-top: -73px;
    }
    .ortam .homepage-text-1 {
        font-size: 15px;
        margin: 0;
    }
    
    .ortam .homepage-text-2 {
        font-size: 22px;
        margin: 0;
    }
    .ortam .homepage-text-3 {
        font-size: 12px;
    }
    .home-btn-1 {
        width: 150px;
    }
    
    .home-btn-2 {
        padding: 10px 0px;
        font-size: 12px;
    }

    .firstcomponentinhomepage {
        padding: 0 12px 60px 12px;
        background-size: 100vw 100vh;
    }

    .ortam .homepage-text-4 {
        font-size: 20px;
        margin: 0;
    }
    
    .ortam .homepage-text-6 {
        margin-left: 15px;
        margin-top: 20px;
        font-size: 8px;
        line-height: 11px;
    }
    .textsidecontainerto {
        padding-top: 7%;
    }
    .ortam .homepage-text-5 {
        font-size: 20px;
    }
}