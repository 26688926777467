.footer-main-container{
    background-color: #1D1D1B;
    width: 100%;
    height:auto;
}
.footer-line{
    width: 100%;
    height: 1px;
    background-color: #FFF9F2;
}
.footer-line-img {
    width: 100%;
    overflow: auto;
}
.footer-bottom-container{
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.footer-main-container .footer-betro-text {
    font-size: 14px;
    margin: 10px 0 0 0;
}
.footer-main-container .footer-menu {
    font-weight: bold;
    font-size: 14px;
}

@media (max-width: 600px) {
    .footer-main-container .footer-menu {
        font-size: 10px;
    }
    .footer-main-container .footer-menu .col-12 {
        padding: 5px 0;
    }
}