.Franchise-main-container {
    width: 100%;
    overflow: hidden;
    background-color:  #1D1D1B;
}
.headerforfranchisepage{
    font-size: 60px;
    color: white;
    text-decoration: underline;
    font-family: 'cindie', sans-serif;
}
.textforfranchisepage{
    font-size: 16px;
    color:white;
    width: 60%;
}
.bg-pic {
    position: relative;
    margin: 0;
    padding: 60px;
    background-image: url("https://betro.s3.eu-central-1.amazonaws.com/franchiseimgone.png");
    background-size: cover;
    background-position: center;
    height: 700px;
    background-repeat: no-repeat;
    z-index: 1;
    text-align: left;
}

.Franchise-header {
    color: #FFF9F2;
    font-size: 70px;
   
    text-decoration: underline;
    position: relative;
    z-index: 2;
}

.Franchise-text {
    color: #FFF9F2;
    
    position: relative;
    z-index: 2;
}

.betro-pic { 
    position: absolute;
    background-image: url("https://betro.s3.eu-central-1.amazonaws.com/betro.png");
    background-size: cover;
    background-position: center;
    width:100%;
    height: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-repeat: no-repeat;
    z-index: 1;
    margin-top: -50px;
}
.informationcontent{
    list-style: none;
}

.titleforfranchise{
    text-decoration: underline;
    margin-bottom: 20px;
    color:#3DAE2B;
    font-family: 'cindie', sans-serif;
}
.textforfranchise{
    color:#FFF9F2;
    margin-bottom: 40px;
}
.astronotpic {
    height: auto;
    width: 636px;
    z-index: 2;
    margin-right: 0; /* Align to the right */
    margin-left: auto; /* Push to the far right */
    display: block; /* Ensure block display */
}


.formcontent{
    position: relative;
    padding-top: 200px;
    width: 100%;
    height:auto;
    display: flex;
    padding-bottom: 50px;
}
.bgnoise {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        opacity: 0.1;
        object-fit: cover; /* Ensure the image covers the area without distortion */
    }
.galaxypic{
    height: auto; /* Otomatik yükseklik */
    z-index: 2; /* İçeriğin üzerinde olacak şekilde z-index */
    width: 264px;
    margin-top:60px;
    margin-left: 200px;
}


.onepic {
    position: absolute;
    top: 100; /* Adjust as needed */
    right: 0; /* Adjust as needed */
    width: 40%; /* Adjust as needed */
    height: auto; /* Adjust as needed */
    z-index: 0; /* Ensure this is lower than the text */
}

.secpic {
    position: absolute;
    bottom: 480px; /* Adjust this value to move it higher */
    right: 160px; /* Position from the right */
    width: 100%; /* Adjust as needed */
    height: auto; /* Adjust as needed */
    z-index: 0; /* Ensure this is lower than the text */
}


/* Optional: Adjust z-index of text to ensure it is above the images */
.textforfranchisepage, .informationcontent, .titleforfranchise {
    position: relative;
    z-index: 1; /* Ensure text is above images */
}
.franchise-submit-button {
    background-color: #3DAE2A;
    border: none;
    border-radius: 100px;
    padding: 7px 40px;
    font-size: 14px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .astronotpic {
        display: none;
    }
    .galaxypic {
        display: none;
    }
}

@media (max-width: 600px) {
    .headerforfranchisepage{
        font-size: 22px;
    }
    .textforfranchisepage{
        font-size: 14px;
        width: 100%;
    }
    .informationcontent {
        padding: 0 10px;
    }
    .textforfranchise {
        font-size: 14px;
    }
}