@font-face {
  font-family: 'general-sans';
  src: url('./fonts/GeneralSans-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'cindie';
  src: url('./fonts/cindie.otf') format('opentype');
}

body {
  margin: 0;
  font-family: 'general-sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  font-family: 'general-sans', sans-serif;
}

p {
  font-family: 'general-sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-navbar .nav-link {
  font-family: 'cindie', sans-serif;
}

.footer-main-container .footer-betro-text {
  font-family: 'cindie', sans-serif;
}