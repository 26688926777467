.MenuBottomSideSelectorforMenuContent {
    position: relative;
    width: 100%;
    background-color: #3DAE2B;
    background-image: url("https://betro.s3.eu-central-1.amazonaws.com/menubar.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  
  .innermenutitle {
    padding-top: 50px;
    margin-left: 40px;
    position: relative;
    display: flex;
    z-index: 2;
    color: white;
  }
  
  .innermenutitle div {
    padding: 30px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .MenuBottomSlidePanel {
    padding-bottom: 100px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slider-arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
  
  .arrow {
    cursor: pointer;
    font-size: 2rem;
    color: white;
    position: absolute;
  }
  
  .arrow-left {
    left: 20px; /* Sol kenarda duracak */
  }
  
  .arrow-right {
    right: 20px; /* Sağ kenarda duracak */
  }
  
  .pageshower {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content:center;
    
  }
  .menuinneritem{
    display: flex;
    justify-content: center;
    align-items: center;
    height:auto;
    color:white
  }
  
  .page-indicator {
    display: flex;
    margin:10px;
    align-items: center;
    justify-content: center;
    width: 200px; /* Uzun çizgilerin genişliği */
    height: 4px; /* Uzun çizgilerin yüksekliği */
    background-color: #ccc;
    cursor: pointer;
    color: white;
    font-weight: bold;
    border-radius: 3px;
  }
  
  .page-indicator.active {
    background-color: white;
  }
  
  .page-indicator span {
    display: inline-block;
    width: 25px; /* Çizgi uzunluğu */
    height: 2px; /* Çizgi kalınlığı */
    background-color: #ccc;
    margin: 0 3px; /* Çizgiler arası boşluk */
  }
  .menuinneritem img{
    width:120px;
    height:auto;
  }
/* MenuSection.css */
.MenuBottomSideSelectorforMenuContent {
  /* Add your existing styles here */
}

.MenuBottomSlidePanel {
  /* Add your existing styles here */
}

.menuinneritem img {
  cursor: pointer;
  border-radius: 10px;
}

/* Ensure the modal has a dark background and no borders */
.modal-content {
  background-color: #36A426;
  border: none;
  border-radius: 20px;
}

/* Flexbox for centering the modal horizontally and vertically */
.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0;
  min-height: 100vh; /* Ensure the modal is vertically centered */
  width: auto; /* Ensure the modal is horizontally centered */
}

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 20px; /* Add some padding for better appearance */
}

.page-indicator {
  /* Your existing styles for page indicators */
}

.page-indicator.active {
  /* Your existing styles for active page indicators */
}

/* Optional: Style for the modal close button if needed */
.modal-header .close {
  color: white;
}

.custom-menu-title {
  margin: 0;
  font-family: 'cindie', sans-serif;
  font-size: 13px;
}

.custom-menu-description {
  font-size: 13px;
}

.model-product-name {
  font-family: 'cindie', sans-serif;
}

.model-row {
  margin-top: 50px;
}

.custom-modal-header {
  border-bottom: none;
}

.custom-modal-header .btn-close-white {
  border-radius: 50px;
  padding: 5px;
}

.inner-menu-active{
  text-decoration: underline;
}

@media (max-width: 600px) {
  .MenuBottomSlidePanel .container {
    padding: 0 30px;
  }
  .menuinneritem {
    margin: 20px 0;
  }

  .innermenutitle {
    width: auto;
    margin: auto;
  }

  .innermenutitle div {
    font-size: 15px;
    padding: 8px;
  }

  .model-row {
    margin-top: 0px;
  }
}