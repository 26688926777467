.custom-navbar {
    background-color: #3dae2c !important;
}

.navbar-collapse-container {
    width: 100%;
    padding-right: 20px;
}

.navbar-nav {
    margin: auto;
}

.custom-navbar .navbar-brand {
    margin: 10px 10px -10px 20px;
}

.custom-navbar .navbar-collapse {
    background-color: #359225 !important;
    clip-path: polygon(5% 0, 100% 0%, 100% 100%, 0% 100%);
    padding: 5px 100px 5px 150px;
    -webkit-box-shadow: inset -40px 61px 7px -55px rgba(0,0,0,0.2);
    box-shadow: inset -40px 61px 7px -55px rgba(0,0,0,0.2);
}

.nav-link.active {
    border-bottom: 1px solid white;
}

.custom-navbar .nav-link {
    color: white;
    font-size: 9px;
    letter-spacing: 1px;
    line-height: 15px;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 1);
    font-weight: bold;
    margin: 0 10px;
}

.custom-navbar .nav-phone-image {
    text-align: center;
}

.custom-navbar .nav-phone-image img {
    margin-left: 50px;
}

@media (max-width: 756px) {
    .custom-navbar .nav-phone-image img {
        margin-left: 0;
    }
    .custom-navbar .navbar-collapse {
        width: 100vw;
        clip-path: none;
    }

    .custom-navbar .nav-link {
        margin: auto;
    }

    .custom-navbar .navbar-collapse {
        padding: 5px 100px 5px 100px;
    }
}