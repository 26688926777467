.joinus-backgroundfor {
    width: 100%;
    height: auto; /* Sayfanın tamamını kaplayacak şekilde ayarlandı */
    background-color: black;
    display: flex;
    justify-content: center; /* İçeriği yatayda ortala */
    align-items: center; /* İçeriği dikeyde ortala */
    color: #FFF;
    padding: 50px;
    overflow: hidden;
    z-index: 1;
    position: relative;
  }
  .bgnoise {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0.5;
    opacity: 0.1;
    object-fit: cover; /* Ensure the image covers the area without distortion */
}

  .starto{
    position: absolute;
    top:50%;
    left:40px;
    width:13px;
    height:13px;
  }
  .startree{
    position: absolute;
    bottom:50px;
    left:60%;
    width:35px;
    height:35px;
  }
  .glasses{
    position: absolute;
    width:200px ;
    height:auto;
    top:20px;
    left:390px;
  }
  .onepic {
    position: absolute;
    bottom: 200px; /* Adjust this value to move it higher */
    left: 0px; /* Position from the right */
    width: 55%; /* Adjust as needed */
    height: auto; /* Adjust as needed */
    z-index: -1;/* Ensure this is lower than the text */
    transform: rotate(180deg);
    
}

.secpic {
    position: absolute;
    top: -200px; /* Adjust as needed */
    right:-300px; /* Adjust as needed */
    width: 100%; /* Adjust as needed */
    height: auto; /* Adjust as needed */
   /* Ensure this is lower than the text */
   z-index: -1;
}
  .joinustitle {
    color: #3DAE2B;
    margin: 0; /* Tüm dış boşlukları kaldırır */
    padding: 0; /* Tüm iç boşlukları kaldırır */
    font-size: 60px;
    line-height: 80px; /* Satır aralığını sıfıra ayarlar */
    margin-bottom: 50px;
    font-family: 'cindie', sans-serif;
  }
  
  .contact-btn {
    margin-top: 40px;
    width: 450px;
  }
  
  .text-white {
    color: #FFF; /* Beyaz renk için text-white stilini tanımladım */
  }
  .seller-img{
    margin-top: 100px;
  }
  .backgroundone{
    padding: 20px;
  }
  .backgroundto{
    padding: 50px;
    margin-top: -100px;
  }

  @media (max-width: 600px) {
    .joinustitle {
      font-size: 30px;
      line-height: 50px;
    }

    .backgroundto{
      padding: 0px;
      margin-top: -50px;
    }
    .glasses{
      left:250px;
    }
}