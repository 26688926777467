.betro-location-container {
    height: auto;
    color: #3DAE2B;
    font-family: 'cindie', sans-serif;
    background-color: black;
    width: 100%;
    padding: 70px;
    overflow: hidden;
    z-index: 1;
    position: relative;
}

.betro-location-container .google-map {
    width: 800px;
    height: 450px;
}

.betro-location-container .col {
    text-align: center;
}

.betro-location-container h1 {
    font-size: 20px;
    font-family: 'cindie', sans-serif;
}

@media (max-width: 600px) {
    .betro-location-container {
        padding-left: 0;
        padding-right: 0;
    }

    .betro-location-container .google-map {
        width: auto;
    }
}

@media (max-width: 768px) and (min-width: 600px) {
    .betro-location-container .google-map {
        width: 600px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (max-width: 992px) and (min-width: 768px) {
    .betro-location-container .google-map {
        width: 700px;
    }
}