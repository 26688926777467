.carousel-wrapper {
  overflow: hidden;
  background-image: linear-gradient(to right, #0B0C0F, #3DAE2B);
  padding: 20px;
  position: relative;
  /* Make the wrapper relative to position static images */
}


.betro-img {
  position: absolute;
  top: 0;
  /* Adjust as needed */
  left: 0;
  /* Adjust as needed */
  width: 100%;
  /* Adjust as needed */
  height: auto;
  /* Maintain aspect ratio */
  z-index: 1;
  /* Ensure it's on top of other elements */
  transform: rotate(-2deg);
}

.bgnoiseformenu {
  position: absolute;
  z-index: 0;
  opacity: 0.05;
}

.galaxy-img {
  position: absolute;
  bottom: 10px;
  /* Adjust as needed */
  right: 10px;
  /* Adjust as needed */
  width: 200px;
  /* Adjust as needed */
  height: auto;
  /* Maintain aspect ratio */
  z-index: 1;
  /* Ensure it's on top of other elements */
}

.slide-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-text {
  color: white;
  width: 45%;
  padding-left: 5%;
  /* Adjusted to reduce the gap */
}

.slide-text h3,
.slide-text p {
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 4rem;
  height: 4rem;
  background-size: 100%, 100%;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M11.354 1.354a.5.5 0 0 1 0 .707L6.707 6.707l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3e%3cpath d='M4.646 1.354a.5.5 0 0 0 0 .707L9.293 6.707l-4.647 4.646a.5.5 0 1 0 .708.708l5-5a.5.5 0 0 0 0-.708l-5-5a.5.5 0 0 0-.708 0z'/%3e%3c/svg%3e");
}

.Titleofslide {
  color: white;
  font-size: 60px;
  font-family: 'cindie', sans-serif;
}

.contextofslide {
  color: white;
  font-size: 16px;
}

.carousel-wrapper .menu-title {
  margin: auto;
  padding-left: 100px;
}

.carousel-wrapper .slide-image {
  margin-left: -400px;
  width: 1000px;
  /* Increase the width to make the image larger */
  height: auto;
}

.carousel-wrapper .menu-row {
  padding: 0 100px;
}

@media (max-width: 1200px) and (min-width: 992px) {
  .carousel-wrapper .slide-image {
    width: 800px;
    margin-left: -300px;
  }
  .Titleofslide {
    font-size: 50px;
  }
  .carousel-wrapper .menu-title {
    padding-left: 50px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .carousel-wrapper .slide-image {
    width: 700px;
    margin-left: -250px;
  }
  .Titleofslide {
    font-size: 40px;
  }
  .carousel-wrapper .menu-title {
    padding-left: 30px;
  }
}

@media (max-width: 768px) and (min-width: 600px) {
  .carousel-wrapper .slide-image {
    width: 500px;
    margin-left: -100px;
    margin-top: -100px;
  }
  .Titleofslide {
    font-size: 30px;
  }
  .carousel-wrapper .menu-title {
    padding-left: 0;
    margin-top: 150px;
  }
  .contextofslide {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .galaxy-img {
    width: 100px;
  }
  .carousel-wrapper .slide-image {
    width: 400px;
    margin-left: -45vw;
    margin-top: -10vh;
  }
  .Titleofslide {
    font-size: 22px;
  }
  .carousel-wrapper .menu-title {
    padding-left: 0;
    margin-top: 100px;
    margin-left: -20px;
  }
  .contextofslide {
    font-size: 12px;
  }
}