.backgroundfor {
  background-image: linear-gradient(to right, #0B0C0F , #3DAE2B);
    width: 100%;
    height: auto;
    margin: 0;
    padding: 50px 0;
    color: #FFF;
    display: grid;
    grid-template-columns: 1fr; /* Tek bir sütun olacak */
    grid-template-rows: auto auto; /* Otomatik yükseklik */
    gap: 20px; /* Sütunlar arasındaki boşluk */
    align-items: center; /* Dikeyde hizalamayı ortala */
    position: relative;
  }
  .bgnoise {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    object-fit: cover; /* Ensure the image covers the area without distortion */
}
.starone{
  position: absolute;
  top:160px;
  right:20%;
  width:35px;
  height:35px;

}
.starto{
  position: absolute;
  top:50%;
  left:40px;
  width:25px;
  height:25px;
}
.startree{
  position: absolute;
  bottom:0;
  left:50%;
  width:35px;
  height:35px;
}
  .Career-betro-pic {
    width: 100%;
    height: auto;
    transform: rotate(-3deg);
  }
  
  .careerside {
    display: grid;
    grid-template-columns: 2fr 1fr; /* İki sütunlu grid */
    gap: 20px; /* Sütunlar arasındaki boşluk */
    align-items: center; /* Dikeyde hizalamayı ortala */
  }
  
  .Careertitle {
    text-decoration: underline;
    font-family: 'cindie', sans-serif;
    
    color:#FFF9F2;
    font-size: 5vw; /* Responsive bir font-size */
    margin: 0; /* Gereksiz boşlukları kaldır */
    padding: 0; /* Gereksiz boşlukları kaldır */
  }
  .Careertxt{
    margin:50px 0px;
    text-align: justify;
  }

  @media (max-width: 600px) {
    .Careertitle {
      text-align: center;
    }
    .Careertxt{
      font-size: 14px;
    }
    .form-label {
      font-size: 14px;
    }
    .careerside-to {
      padding: 30px;
    }
}